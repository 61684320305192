<template>
  <div class="btn-container" @click="onClick">
    <div class="content" :class="{ fontsize: fontsize }">خرید</div>
  </div>
</template>

<script>
export default {
  name: "BuyButton",
  props: {
    onClick: {
      type: Function,
      require: true
    },
    fontsize: {}
  }
};
</script>

<style scoped>
.btn-container {
  max-width: 450px;
  width: 100%;
  background: #039100;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  cursor: pointer;
  max-height: 40px;
}

.btn-container:hover {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.28);
}

.content {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 0;
  text-align: center;
}

.fontsize {
  font-size: 20px;
}

@media (max-width: 500px) {
  .btn-container {
    width: 100%;
  }
}
</style>
