<template>
  <div id="buy-bitcoin">
    <ConfirmPurchaseRequest
      v-show="confirmPurchaseModal"
      @closeModal="confirmPurchaseModalHandle"
      :unit-price="
        item ? Number(item.purchase_price_from_us).toLocaleString() : '----'
      "
      :wallet-inventory="
        item ? Number(item.user_wallet_balance).toLocaleString() : '----'
      "
      :total-price="tomman"
      :amount="amount"
    />
    <LackOfInventory
      :amount="amount"
      :total-price="tomman"
      v-if="lackOfInventoryModal"
      @closeModal="lackOfInventoryHandle"
      :unit-price="
        item ? Number(item.purchase_price_from_us).toLocaleString() : '----'
      "
      :wallet-inventory="
        item ? Number(item.user_wallet_balance).toLocaleString() : '----'
      "
    />
    <SuccessAlert
      title="خرید با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-if="successMessageModal"
    />
    <div class="bitcoin-container">
      <div class="amount-of-bitcoin-form-section">
        <div class="amount-of-bitcoin-form-section__header">
          مقدار ووچر*
        </div>
        <div class="bitcoin-amount-input">
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.amount }">
              <img
                src="../../assets/images/perfect-money-voucher-icon.svg"
                class="amount-input__image"
                alt=""
              />
              <label style="width: 100%">
                <input
                  type="text"
                  style="width: 100%;text-align: center; font-size: 16px"
                  v-model="amount"
                  @input="setAmount($event)"
                  ref="amount"
                />
              </label>
              <div class="amount-input__symbol">PM</div>
            </div>
            <div class="error-message">
              {{ error.amount }}
            </div>
          </div>
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.tomman }">
              <img
                src="../../assets/images/iran.svg"
                alt=""
                class="amount-input__image"
              />
              <label style="width: 100%">
                <input
                  type="text"
                  style="width: 100%;text-align: center; font-size: 16px"
                  v-model="tomman"
                  @input="setToman($event)"
                  ref="tomman"
                />
              </label>
              <div class="amount-input__symbol">تومان</div>
            </div>
            <div class="error-message">
              {{ error.tomman }}
            </div>
          </div>
        </div>
        <div class="tomman-amount-input"></div>
        <div class="bitcoin-amount-details">
          <div class="bitcoin-amount-details__span">
            موجودی :
            <span
              >{{ item ? item.symbol : "----" }}
              {{ item ? item.inventory : "----" }}</span
            >
          </div>
          <div class="bitcoin-amount-details__span">
            موجودی تومانی :
            <span>{{
              item ? Number(item.tommani_inventory).toLocaleString() : "----"
            }}</span>
          </div>
        </div>
      </div>
      <div class="purchase-price-from-site">
        <div class="purchase-price-from-site_header">
          هر دلار پرفکت مانی
        </div>
        <div class="purchase-price-form-site_price">
          <span>{{
            item ? Number(item.purchase_price_from_us).toLocaleString() : "----"
          }}</span>
          تومان
        </div>
      </div>

      <div class="how-to-pay-section">
        <div class="how-to-pay__header">
          نحوه دریافت پول
        </div>
        <div class="how-to-pay__selected-section">
          <div class="how-to-pay__radio-wrapper">
            <div class="how-to-pay__radio">
              <input type="radio" id="huey" value="gate" v-model="pay_method" />
              <label for="huey">درگاه بانکی</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="dewey"
                v-model="pay_method"
                value="wallet"
              />
              <label for="dewey"
                >کیف پول (موجودی:
                <span>{{
                  item
                    ? Number(item.user_wallet_balance).toLocaleString()
                    : "----"
                }}</span
                >)</label
              >
            </div>
          </div>
          <div class="how-to-pay__information">
            با شارژ کیف پول میتوانید پرداخت های خود را بدون رفتن به درگاه بانکی
            پرداخت کنید.
          </div>
        </div>
        <div class="description-section">
          <div class="description-header">توضیحات</div>
          <label>
            <textarea
              class="description-textarea"
              name=""
              v-model="description"
            ></textarea>
          </label>
        </div>
      </div>
      <div class="buy-button-wrapper">
        <BuyButton class="buy-button" :fontsize="true" :onClick="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import BuyButton from "@/components/Button/BuyButton";
import ConfirmPurchaseRequest from "@/components/Modals/ConfirmPurchaseRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
import LackOfInventory from "@/components/Modals/LackOfInventory";
import axios from "axios";

export default {
  name: "BuyVoucherPerfectMoney",
  components: {
    LackOfInventory,
    SuccessAlert,
    ConfirmPurchaseRequest,
    BuyButton
  },
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      showBuyModal: false,
      successMessageModal: false,
      lackOfInventoryModal: false,
      confirmPurchaseModal: false,
      description: null,
      pay_method: "gate",
      amount: null,
      tomman: null,
      error: {}
    };
  },
  methods: {
    async toggleConfirmPurchaseRequest() {},
    lackOfInventoryHandle() {
      this.lackOfInventoryModal = false;
    },
    async confirmPurchaseModalHandle() {
      let tomman = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));
      let data = {
        crypto_id: this.item.id,
        cost: tomman,
        amount: this.amount,
        description: this.description
      };

      let loader = this.$loading.show();
      try {
        let response = await axios({
          url: `/panel/order?pay_method=wallet&type=buy&order_method=voucher`,
          method: "POST",
          data: data
        });
        if (!response.data.status) {
          // this.confirmPurchaseModal = false;
          loader.hide();
          return false;
        }
      } catch (error) {
        console.log(error);
      }
      loader.hide();
      this.confirmPurchaseModal = false;
      this.successMessageModal = true;
      setTimeout(() => {
        this.successMessageModal = false;
      }, 2000);
    },
    toggleShowSuccessModal() {
      this.successMessageModal = false;
      if (!this.showSuccessModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    async submit() {
      this.error = {};
      if (!this.amount || this.amount == "0") {
        this.error.amount = "تعداد را وارد کنید";
        this.$refs.amount.focus();
      }

      if (!this.tomman || this.tomman == "0") {
        this.error.tomman = "قیمت نمیتواند خالی باشد";
        this.$refs.amount.focus();
        return false;
      }

      if (this.pay_method === "gate") {
        console.log("gate");
        return false;
      }

      let tomman = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));

      if (this.pay_method === "wallet") {
        if (this.item.user_wallet_balance < tomman) {
          this.lackOfInventoryModal = true;
          return false;
        }

        this.confirmPurchaseModal = true;
      }
    },
    setAmount(e) {
      // console.log(e);
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (value[value.length - 2] === undefined) data = null;

      if (reg.test(value[value.length - 2] + data)) {
        if (value === null) value = "";
        if (!reg.test(value)) value.slice(0, -1);
        if (value === "") value = null;
      } else {
        value = value.replace(data, "");
      }

      this.amount = value;
      this.tomman = (this.item.purchase_price_from_us * value).toLocaleString();
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    },
    setToman(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else {
        value = value.replace(data, "");
      }

      this.tomman = value;
      this.amount = value / this.item.purchase_price_from_us;
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    }
  }
};
</script>

<style scoped>
.bitcoin-container {
  width: 100%;
  /*min-height: 743px;*/
  border-radius: 10px;
  background: rgba(3, 145, 0, 0.1);
  /*padding: 20px;*/
  padding: 5px;
}

.bitcoin-amount-input {
  width: 100%;
}

.amount-input__wrapper {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 0 10px;
  /* height: 100%; */
  outline: none;
  border: none;
}

.bitcoin-amount-details {
  display: flex;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
  gap: 20px;
}

.bitcoin-amount-details__span {
  font-weight: normal;
  font-size: 12px;
  color: rgba(18, 18, 18, 0.7);
  display: flex;
  /*width: 100%;*/
}

.amount-input__symbol {
  color: rgba(18, 18, 18, 0.7);
}

.amount-input__image {
  width: 22px;
}

.amount-of-bitcoin-form-section {
  margin-bottom: 20px;
}

.amount-of-bitcoin-form-section__header {
  color: #121212;
  font-size: 16px;
}

.purchase-price-from-site {
  margin-bottom: 20px;
}

.purchase-price-form-site_price {
  background: rgba(255, 255, 255, 0.5);
  text-align: end;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  color: rgba(18, 18, 18, 0.7);
}

.transfer-fee-section {
  margin-bottom: 20px;
}

.transfer-fee-section {
  width: 100%;
}

.transfer-fee-wrapper {
  width: 100%;
  background: #fff;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #6b88d1;
  gap: 25px;
}

.wallet-address-section {
  margin-bottom: 20px;
}

.wallet-address-wrapper__header {
  font-size: 16px;
  color: #121212;
}

.wallet-address-input {
  width: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 0 4px 12px;
  direction: ltr;
  color: rgba(18, 18, 18, 0.7);
}

.how-to-pay__header {
  font-size: 16px;
  color: #121212;
  margin-bottom: 30px;
}

.how-to-pay__radio-wrapper {
  display: flex;
  gap: 90px;
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

label {
  font-size: 14px;
}

.how-to-pay__information {
  font-size: 12px;
  margin-top: 10px;
  color: #121212;
}

.how-to-pay__selected-section {
  margin-bottom: 20px;
}

.description-textarea {
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 110px;
  resize: none;
}

.description-header {
  font-weight: normal;
  font-size: 16px;
}

.buy-button {
  margin: 10px auto;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 768px) {
  .bitcoin-container {
    padding: 20px;
  }
}
</style>
